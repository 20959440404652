import { Link, Typography } from "@mui/material";

export default function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center" sx={{ displayPrint: "none" }}>
            {'Copyright © '}
            <Link color="inherit" href="https://eordogh.hu/">
                Eördögh Trade Kft.
            </Link>{' '}
            {new Date().getFullYear()}
            {'. - Minden jog fenntartva.'}
        </Typography>
    );
}