import { Paper, Typography, Box, CircularProgress, Table, TableBody, TableRow, TableCell, Button, Container, TextField, AppBar, Toolbar, Grid } from "@mui/material";
import { GridColDef, GridRowsProp, GridSortModel } from "@mui/x-data-grid";
import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import ApplicationStore from "Stores/ApplicationStore";
import { DataGrid } from '@mui/x-data-grid';
import { get } from "lodash";
import { DatePicker } from "@mui/x-date-pickers";

function SubjectAppointmentList() {

    useEffect(() => ApplicationStore.loadSubjectAppointmentListPage(), []);

    const columns = useMemo<GridColDef[]>(() => {
        const colDataRaw = ApplicationStore.parameters!.get("subject_appointment_list_columns")!;
        const cols = colDataRaw.split(";");

        return [
            ...cols.map<GridColDef>(c => {
                const [field, headerName] = c.split(":");
                return {
                    field,
                    headerName,
                    flex: 1,
                    filterable: false,
                    valueGetter: p => get(p.row, field)
                };
            })
        ];
    }, []);

    const sortModel: GridSortModel = [
        {
            field: ApplicationStore.subjectAppointmentListOrderBy,
            sort: ApplicationStore.subjectAppointmentListOrderByDescending ? "desc" : "asc"
        }
    ];

    useEffect(() => {

        const timer = setInterval(
            () => ApplicationStore.loadSubjectAppointmentListPage(),
            5 * 60 * 1000
        );

        return () => {
            clearInterval(timer);
        }

    }, []);

    return (
        <>
            <AppBar
                position="absolute"
                color="default"
                elevation={0}
                sx={{
                    position: 'relative',
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                    displayPrint: "none"
                }}
            >
                <Toolbar>
                    <div>
                        <Typography variant="h6" color="inherit" noWrap>
                            {ApplicationStore.parameters!.get("text__subject_appointment_list_title")}
                        </Typography>
                        <Typography variant="overline" display="block" sx={{ lineHeight: 1 }}>
                            Frissítve: {ApplicationStore.subjectAppointmentListLastRefreshedAt.format("L LTS")}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="xl" sx={{ mb: 4, mt: 2 }}>
                <Grid container spacing={2} sx={{ mb: 1, mt: 1 }}>
                    <Grid item xs={6} md={3}>
                        <DatePicker
                            label="Dátumtól"
                            value={ApplicationStore.subjectAppointmentListFrom}
                            onChange={ApplicationStore.setSubjectAppointmentListFrom}
                            renderInput={(params) => <TextField {...params} fullWidth sx={{ displayPrint: "none" }} size="small" />}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <DatePicker
                            label="Dátumig"
                            value={ApplicationStore.subjectAppointmentListTo}
                            onChange={ApplicationStore.setSubjectAppointmentListTo}
                            renderInput={(params) => <TextField {...params} fullWidth sx={{ displayPrint: "none" }} size="small" />}
                        />
                    </Grid>
                    <Grid item xs={10} md={4}>
                        <TextField
                            required
                            label="Keresés"
                            fullWidth
                            variant="outlined"
                            value={ApplicationStore.searchText}
                            onChange={e => ApplicationStore.setSubjectAppointmentSearchText(e.target.value)}
                            sx={{ displayPrint: "none" }}
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button onClick={ApplicationStore.loadSubjectAppointmentListPage} sx={{ displayPrint: "none" }} variant="contained">
                            Frissítés
                        </Button>
                    </Grid>
                </Grid>

                {ApplicationStore.isLoading || !ApplicationStore.subjectAppointmentList ? (
                    <Box sx={{ display: "flex" }}>
                        <CircularProgress disableShrink />
                    </Box>
                ) : (
                    <>
                        <Box sx={{ height: "calc(100vh - 190px)", width: '100%', "@media print": { height: "calc(100vh - 50px)" } }}>
                            <DataGrid
                                rows={ApplicationStore.subjectAppointmentList!.items}
                                columns={columns}
                                sortModel={sortModel}
                                sortingMode="server"
                                onSortModelChange={s => {
                                    return ApplicationStore.setSubjectAppointmentListOrderBy(s.length === 0 ? "Name" : s[0].field, s.length === 0 ? false : s[0].sort === "desc");
                                }}
                                getRowId={r => `${r.appointmentDateTime}_${r.subject.id}`}
                                paginationMode="server"
                                pagination={true}
                                page={ApplicationStore.subjectAppointmentListPage}
                                pageSize={ApplicationStore.subjectAppointmentListPageSize}
                                onPageChange={ApplicationStore.setSubjectAppointmentListPage}
                                rowCount={ApplicationStore.subjectAppointmentList.totalCount}
                            />
                        </Box>
                    </>
                )}
            </Container>
        </>
    );
}

export default observer(SubjectAppointmentList);