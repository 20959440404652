import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import NavigationStore from "Stores/NavigationStore";

interface INavButtonsProps {
    showBackButton?: boolean;

    onNext?: () => void;
}

export default function NavButtons(props: INavButtonsProps) {

    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {props.showBackButton && (
                <Button onClick={NavigationStore.navigateBack} sx={{ mt: 3, ml: 1 }}>
                    Vissza
                </Button>
            )}
            {props.onNext && (
                <Button
                    variant="contained"
                    onClick={props.onNext}
                    sx={{ mt: 3, ml: 1 }}
                >
                    Következő
                </Button>
            )}
        </Box>
    );
}