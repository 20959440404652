import ApiAdapterBase from "ApiAdapter/ApiAdapterBase";
import { Config } from "Config";
import { IParameter } from "Model/IParameter";

const baseUrl = `${Config.baseUrl}/api/parameter`;
const headers = {
    "Content-Type": "application/json"
};

class ParameterApiAdapter extends ApiAdapterBase {

    public async getAsync(): Promise<IParameter[]> {
        const response = await fetch(`${baseUrl}`, {
            method: "GET",
            headers
        });

        this.handleError(response);
        return await response.json();
    }
}

export default new ParameterApiAdapter();