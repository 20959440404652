import React from "react";
import { AppBar, Container, Toolbar, Typography } from "@mui/material";
import ApplicationStore from "Stores/ApplicationStore";

export default function PageWrapper(props: React.PropsWithChildren<{}>) {
    return (
        <>
            <AppBar
                position="absolute"
                color="default"
                elevation={0}
                sx={{
                    position: 'relative',
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}
            >
                <Toolbar>
                    <Typography variant="h6" color="inherit" noWrap>
                        {ApplicationStore.parameters!.get("text__header_title")}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
                {props.children}
            </Container>
        </>
    );
}