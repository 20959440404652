import AppointmentApiAdapter from "ApiAdapter/AppointmentApiAdapter";
import LabelApiAdapter from "ApiAdapter/ParameterApiAdapter";
import SubjectApiAdapter from "ApiAdapter/SubjectApiAdapter";
import { action, makeObservable, observable, runInAction } from "mobx";
import { ITimeSlot } from "Model/ITimeSlot";
import { IAppointmentSchedule } from "Model/IAppointmentSchedule";
import { IExistingAppointment } from "Model/IExistingAppointment";
import moment from "moment";
import NavigationStore from "Stores/NavigationStore";
import { IPagedItems } from "Model/IPagedItems";
import { ISubject } from "Model/ISubject";
import AdminSubjectApiAdapter from "ApiAdapter/Admin/AdminSubjectApiAdapter";
import { debounce } from "lodash";
import AdminAppointmentApiAdapter from "ApiAdapter/Admin/AdminAppointmentApiAdapter";
import { ISubjectAppointment } from "Model/ISubjectAppointment";

class ApplicationStore {

    @observable.ref public isLoading = false;
    @observable.ref public subjectId: string | null = null;
    @observable.ref public existingAppointment: IExistingAppointment | null = null;
    @observable.ref public subjectPlannedAppointmentDateTime: string | null = null;
    @observable.ref public error: Error | null = null;
    @observable.ref public parameters: Map<string, string | null> | null = null;

    private isMobile: boolean = false;
    public currentTimeSlotsFrom: moment.Moment | null = null;
    public currentTimeSlotsTo: moment.Moment | null = null;
    public minTimeSlotsFrom = moment().startOf("day");
    @observable.ref public schedule: IAppointmentSchedule | null = null;
    @observable.ref public confirmationSecondsLeft: number = 0;

    @observable.ref public subjectList: IPagedItems<ISubject> | null = null;
    @observable.ref public subjectListPage = 0;
    @observable.ref public subjectListOrderBy: string = "name";
    @observable.ref public subjectListOrderByDescending = false;

    @observable.ref public subjectAppointmentList: IPagedItems<ISubjectAppointment> | null = null;
    @observable.ref public subjectAppointmentListPage = 0;
    @observable.ref public subjectAppointmentListOrderBy: string = "appointmentDateTime";
    @observable.ref public subjectAppointmentListOrderByDescending = false;
    @observable.ref public subjectAppointmentListFrom = moment().startOf("day");
    @observable.ref public subjectAppointmentListTo = moment().endOf("day");
    @observable.ref public subjectAppointmentListLastRefreshedAt = moment();

    @observable.ref public searchText: string = "";

    public readonly subjectListPageSize = 50;
    public readonly subjectAppointmentListPageSize = 50;

    private confirmationTimer: any = null;

    constructor() {
        makeObservable(this);
    }

    public load() {
        this.fireAndForget(this.wrapAsync(async () => {
            const labels = await LabelApiAdapter.getAsync();
            runInAction(() => {
                this.parameters = new Map<string, string | null>(labels.map(l => [l.name, l.value]));
                window.document.title = this.parameters.get("text__title")!;
            });
        }));
    }

    public loadSubjectPage(subjectId: string) {
        this.fireAndForget(this.wrapAsync(async () => {
            runInAction(() => {
                this.subjectId = subjectId;
            });

            await this.ensureExistingAppointmentLoadedAsync();

            if (this.existingAppointment) {
                NavigationStore.navigateTo(`/${this.subjectId}/existing`);
            } else {
                // or get secret
                NavigationStore.navigateTo(`/${this.subjectId}/timeslots`);
            }
        }));

    }

    public loadAppointmentsPage(subjectId: string, isMobile: boolean) {
        this.fireAndForget(this.wrapAsync(async () => {

            runInAction(() => {
                this.isMobile = isMobile;
                this.subjectId = subjectId;
            });

            const plannedAppointment = await SubjectApiAdapter.getPlannedAppointmentAsync(this.subjectId!);

            runInAction(() => {
                this.subjectPlannedAppointmentDateTime = plannedAppointment;
            });

            let fromCandidate = isMobile ? moment().startOf("day") : moment().startOf("week");
            if (fromCandidate.isBefore(moment(this.parameters!.get("time_slots_min_date")))) {
                fromCandidate = moment(this.parameters!.get("time_slots_min_date")).startOf("week");
            }

            this.currentTimeSlotsFrom = fromCandidate;
            this.currentTimeSlotsTo = isMobile ? this.currentTimeSlotsFrom.clone().add(1, "day") : this.currentTimeSlotsFrom.clone().add(5, "days");

            await this.loadAppointmentScheduleAsync();
        }));
    }

    public loadExistingPage(subjectId: string) {
        this.fireAndForget(this.wrapAsync(async () => {
            runInAction(() => {
                this.subjectId = subjectId;
            });
            await this.ensureExistingAppointmentLoadedAsync();

            if (this.existingAppointment === null) {
                alert(this.parameters!.get("text__cannot_select_appointment"));
                NavigationStore.navigateToHome();
            }
        }));
    }

    public loadSubjectListPage() {
        this.fireAndForget(this.wrapAsync(async () => {
            await this.loadSubjectListAsync();
        }));
    }

    @action.bound
    public loadSubjectAppointmentListPage() {
        this.fireAndForget(this.wrapAsync(async () => {
            await this.loadSubjectAppointmentListAsync();
        }));
    }

    public readonly setSubjectListPage = (page: number) => {
        this.subjectListPage = page;
        this.fireAndForget(this.wrapAsync(async () => {
            await this.loadSubjectListAsync();
        }));
    };

    public readonly setSubjectAppointmentListPage = (page: number) => {
        this.subjectAppointmentListPage = page;
        this.fireAndForget(this.wrapAsync(async () => {
            await this.loadSubjectAppointmentListAsync();
        }));
    };

    @action.bound
    public setSubjectListOrderBy(field: string, orderByDesc: boolean) {
        this.subjectListOrderBy = field;
        this.subjectListOrderByDescending = orderByDesc;
        this.fireAndForget(this.wrapAsync(async () => await this.loadSubjectListAsync()));
    };

    @action.bound
    public setSubjectAppointmentListOrderBy(field: string, orderByDesc: boolean) {
        this.subjectAppointmentListOrderBy = field;
        this.subjectAppointmentListOrderByDescending = orderByDesc;
        this.fireAndForget(this.wrapAsync(async () => await this.loadSubjectAppointmentListAsync()));
    };

    private async loadSubjectListAsync() {
        const items = await AdminSubjectApiAdapter.getAsync(
            this.subjectListPage * this.subjectListPageSize,
            this.subjectListPageSize,
            this.searchText,
            this.subjectListOrderBy,
            this.subjectListOrderByDescending
        );

        runInAction(() => {
            this.subjectList = items;
        });
    }

    private async loadSubjectAppointmentListAsync() {
        const items = await AdminAppointmentApiAdapter.getAsync(
            this.subjectAppointmentListFrom!,
            this.subjectAppointmentListTo!,
            this.searchText,
            this.subjectAppointmentListOrderBy,
            this.subjectAppointmentListOrderByDescending,
            this.subjectAppointmentListPage * this.subjectAppointmentListPageSize,
            this.subjectAppointmentListPageSize,
        );

        runInAction(() => {
            this.subjectAppointmentList = items;
            this.subjectAppointmentListLastRefreshedAt = moment();
        });
    }

    private loadAppointmentSchedule() {
        this.fireAndForget(this.wrapAsync(async () => {
            await this.loadAppointmentScheduleAsync();
        }));
    }

    @action.bound
    public setWeekDate(newDate: Date) {
        this.currentTimeSlotsFrom = this.isMobile ? moment(newDate).startOf("day") : moment(newDate).startOf("week");
        this.currentTimeSlotsTo = this.isMobile ? this.currentTimeSlotsFrom.clone().add(1, "day") : this.currentTimeSlotsFrom.clone().add(5, "days");
        this.loadAppointmentSchedule();
    }

    public readonly selectAppointment = (appointment: ITimeSlot) => {
        if (!appointment.isFree) return;
        this.fireAndForget(this.wrapAsync(async () => {
            const newAppointment = await AppointmentApiAdapter.selectAsync({
                appointmentId: appointment.appointmentId,
                subjectId: this.subjectId!
            });

            if (newAppointment === null) {
                alert(this.parameters!.get("text__cannot_select_appointment"));
                return;
            }

            this.setExistingAppointment(newAppointment);
            NavigationStore.navigateTo(`/${this.subjectId}/existing`);
        }));
    }

    private async loadAppointmentScheduleAsync() {
        const schedule = await AppointmentApiAdapter.getAsync(this.currentTimeSlotsFrom!, this.currentTimeSlotsTo!);
        runInAction(() => {
            this.schedule = schedule;
        });
    }

    private async ensureExistingAppointmentLoadedAsync() {
        if (this.existingAppointment || !this.subjectId) return;
        const existingAppointment = await AppointmentApiAdapter.getExistingAppointmentAsync(this.subjectId);
        this.setExistingAppointment(existingAppointment);
    }

    @action
    private setExistingAppointment(existing: IExistingAppointment | null) {
        this.existingAppointment = existing;
        this.setConfirmationTimer();
    }

    private setConfirmationTimer() {
        clearInterval(this.confirmationTimer);

        if (!this.existingAppointment || this.existingAppointment.isApproved)
            return;

        this.confirmationSecondsLeft = this.existingAppointment.autoCancelAfterSeconds!;
        this.confirmationTimer = setInterval(action(() => {
            this.confirmationSecondsLeft--;
        }), 1000);
    }

    public setSubjectId(subjectId: string) {
        if (!subjectId) {
            alert(this.parameters?.get("text__please_fill_subject_id"));
            return;
        }

        const subjectIdRegexPattern = this.parameters?.get("subject_id_regex_pattern");
        if (subjectIdRegexPattern && !new RegExp(subjectIdRegexPattern).test(subjectId)) {
            alert(this.parameters?.get("text__invalid_subject_id"));
            return;
        }

        NavigationStore.navigateTo(`/${subjectId}`);
    }

    public readonly approveAppointment = () => {
        this.fireAndForget(this.wrapAsync(async () => {
            const existingAppointment = await AppointmentApiAdapter.approveAsync({
                appointmentId: this.existingAppointment!.id,
                subjectId: this.subjectId!
            });
            this.setExistingAppointment(existingAppointment);
        }));
    }

    public readonly cancelAppointment = () => {
        this.fireAndForget(this.wrapAsync(async () => {
            await AppointmentApiAdapter.cancelAsync({
                appointmentId: this.existingAppointment!.id,
                subjectId: this.subjectId!
            });
            alert("Sikeres lemondás");
            NavigationStore.navigateToHome();
        }));
    }

    @action.bound
    public startOver() {
        clearInterval(this.confirmationTimer);
        this.subjectId = null;
        this.existingAppointment = null;
        this.subjectPlannedAppointmentDateTime = null;
        this.schedule = null;
        NavigationStore.navigateToHome();
    }

    private async wrapAsync(asyncAction: () => Promise<void>) {
        try {
            runInAction(() => this.isLoading = true);
            asyncAction();
        }
        finally {
            runInAction(() => this.isLoading = false);
        }
    }

    private fireAndForget(promise: Promise<void>) {
        promise.catch(action(e => this.error = e));
    }

    private readonly reloadSubjectListPageDebounced = debounce(() => this.fireAndForget(this.wrapAsync(async () => await this.loadSubjectListAsync())), 500);

    @action.bound
    public setSearchText(newValue: string) {
        this.searchText = newValue;
        this.reloadSubjectListPageDebounced();
    }

    private readonly reloadSubjectAppointmentListPageDebounced = debounce(() => this.fireAndForget(this.wrapAsync(async () => await this.loadSubjectAppointmentListAsync())), 500);

    @action.bound
    public setSubjectAppointmentSearchText(newValue: string) {
        this.searchText = newValue;
        this.reloadSubjectAppointmentListPageDebounced();
    }

    @action.bound
    public setSubjectAppointmentListFrom(value: moment.Moment | null) {
        this.subjectAppointmentListFrom = value?.startOf("day") ?? moment().startOf("day");
        this.reloadSubjectAppointmentListPageDebounced();
    }

    @action.bound
    public setSubjectAppointmentListTo(value: moment.Moment | null) {
        this.subjectAppointmentListTo = value?.endOf("day") ?? moment().endOf("day");
        this.reloadSubjectAppointmentListPageDebounced();
    }
}

export default new ApplicationStore();
