import AdminKeyStore from "ApiAdapter/Admin/AdminKeyStore";
import ApiAdapterBase from "ApiAdapter/ApiAdapterBase";
import { Config } from "Config";
import { IPagedItems } from "Model/IPagedItems";
import { ISubject } from "Model/ISubject";

const baseUrl = `${Config.baseUrl}/api/admin/subject`;
const headers = {
    "Content-Type": "application/json"
};

class AdminSubjectApiAdapter extends ApiAdapterBase {
    public async getAsync(skip: number, take: number, search: string | null, orderBy: string, orderByDesc: boolean): Promise<IPagedItems<ISubject>> {
        const qp = new URLSearchParams();
        qp.set("skip", skip.toString());
        qp.set("take", take.toString());
        if (search) qp.set("search", search);
        qp.set("orderBy", orderBy);
        qp.set("orderByDesc", orderByDesc ? "true" : "false");

        const response = await fetch(`${baseUrl}?${qp.toString()}`, {
            method: "GET",
            headers: {
                ...headers,
                "Authorization": AdminKeyStore.getAdminKey()
            }
        });

        if (response.status === 401) {
            AdminKeyStore.clear();
        }

        this.handleError(response);
        const result = await response.json();

        return {
            totalCount: result.totalCount,
            items: (result.items as any[]).map(i => ({
                id: i.id,
                plannedAppointment: i.plannedAppointment,
                name: i.name,
                email: i.email?.toLowerCase(),
                phone: i.phone,
                taxNumber: i.taxNumber,
                apNumber: i.apNumber,
                misc: i.misc,
            }))
        };
    }
}

export default new AdminSubjectApiAdapter();