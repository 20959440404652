import { Paper, Typography, Box, CircularProgress, useMediaQuery, useTheme, Container } from "@mui/material";
import { TimeInDay } from "Common/TimeInDay";
import NavButtons from "Components/Controls/NavButtons";
import PageWrapper from "Components/Controls/PageWrapper";
import Steps from "Components/Controls/Steps";
import Timetable from "Components/Controls/Timetable";
import { observer } from "mobx-react";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router";
import ApplicationStore from "Stores/ApplicationStore";

function TimeSlotsPage() {

    const dayStartTime = useMemo(() => {
        if (ApplicationStore.schedule?.schedule.dayStartTime) {
            const segments = ApplicationStore.schedule.schedule.dayStartTime!.split(":");
            return new TimeInDay(parseInt(segments[0]), parseInt(segments[1]));
        }
        return null;
    }, [ApplicationStore.schedule?.schedule.dayStartTime]);

    const dayEndTime = useMemo(() => {
        if (ApplicationStore.schedule?.schedule.dayEndTime) {
            const segments = ApplicationStore.schedule.schedule.dayEndTime!.split(":");
            return new TimeInDay(parseInt(segments[0]), parseInt(segments[1]));
        }
        return null;
    }, [ApplicationStore.schedule?.schedule.dayEndTime]);

    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    const { subjectId } = useParams();
    useEffect(() => ApplicationStore.loadAppointmentsPage(subjectId!, isMobile), [subjectId]);

    return (
        <PageWrapper>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography component="h1" variant="h4" align="center">
                    {ApplicationStore.parameters!.get("text__time_slots_page_title")}
                </Typography>
                <Steps activeStep={1} />
                <p>{ApplicationStore.subjectPlannedAppointmentDateTime && ApplicationStore.parameters!.get("text__planned_appointment_message")?.replace("{appointment}", ApplicationStore.subjectPlannedAppointmentDateTime)}</p>
                {ApplicationStore.isLoading || !dayStartTime || !dayEndTime || !ApplicationStore.schedule || !ApplicationStore.currentTimeSlotsFrom ? (
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress disableShrink />
                    </Box>
                ) : (
                    <Timetable
                        dayStartTime={dayStartTime!}
                        dayEndTime={dayEndTime!}
                        days={isMobile ? 1 : 5}
                        isMobile={isMobile}
                        slotDuration={ApplicationStore.schedule.schedule.slotDurationMinutes}
                        weekDate={ApplicationStore.currentTimeSlotsFrom.toDate()}
                        onWeekDateChanged={ApplicationStore.setWeekDate}
                        appointmentInSlot={ApplicationStore.schedule.schedule.numberOfAppointmentsInSlot}
                        appointments={ApplicationStore.schedule.timeSlots}
                        onSelectAppointment={ApplicationStore.selectAppointment}
                        minDate={ApplicationStore.minTimeSlotsFrom.toDate()}
                        maxDate={moment(ApplicationStore.parameters!.get("time_slots_max_date")).toDate()}
                    />
                )}
                <NavButtons showBackButton />
            </Paper>
        </PageWrapper>
    );
}

export default observer(TimeSlotsPage);