export class TimeInDay {
    constructor(public hour: number, public minute: number) {
    }

    withAddedMinutes(toAdd: number) {
        let minute = this.minute + toAdd;
        let hour = this.hour + Math.floor(minute / 60);
        minute = minute % 60;

        return new TimeInDay(hour, minute);
    }

    isEarlierThan(other: TimeInDay) {
        return this.hour < other.hour ? true : (this.hour === other.hour && this.minute < other.minute ? true : false);
    }

    isEarlierThanOrEqualTo(other: TimeInDay) {
        return this.hour < other.hour ? true : (this.hour === other.hour && this.minute <= other.minute ? true : false);
    }

    toString() {
        return this.hour.toString() + ":" + (this.minute < 10 ? "0" : "") + this.minute.toString();
    }
}