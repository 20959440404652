import React from 'react';
import moment from 'moment';

import clone from "lodash/clone";
import map from "lodash/map";
import find from "lodash/find";
import remove from "lodash/remove";

import { TimeInDay } from '../../Common/TimeInDay';
import { Button, ButtonGroup, FormControl, InputLabel, NativeSelect, styled, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ITimeSlot } from 'Model/ITimeSlot';

const _ = {
    clone,
    map,
    find,
    remove
};

interface IWeekTimetableProps {
    dayStartTime: TimeInDay;
    dayEndTime: TimeInDay;
    slotDuration: number;
    weekDate: Date;
    appointmentInSlot?: number;
    appointments: ITimeSlot[];
    onSelectAppointment?: (appointment: ITimeSlot) => void;
    className?: string;
    days?: number;
    minDate: Date;
    maxDate: Date;
    isMobile: boolean;

    onWeekDateChanged?: (newDate: Date) => void;
}

interface IWeekTimetableState {
    weekDate: Date;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default class WeekTimetable extends React.Component<IWeekTimetableProps, IWeekTimetableState> {

    private days: number = 0;

    constructor(props: IWeekTimetableProps) {
        super(props);

        if (this.props.dayEndTime.isEarlierThan(this.props.dayStartTime)) {
            throw Error("The dayEndTime cannot be earlier than dayStartTime!");
        }

        this.state = {
            weekDate: this.props.weekDate
        };

        this.days = this.props.days ? this.props.days : 5;
    }

    renderTimeslots(days: moment.Moment[]) {

        let currentTime = this.props.dayStartTime;
        let index = 0;
        let returnArray = [];
        const appointmentInSlot = this.props.appointmentInSlot || 1;

        let appointments = _.clone(this.props.appointments);
        // let style = styles.tableRowAlter1;

        while (currentTime.isEarlierThanOrEqualTo(this.props.dayEndTime)) {

            for (let appointmentIndex = 0; appointmentIndex < appointmentInSlot; ++appointmentIndex) {

                returnArray.push(
                    <StyledTableRow key={index++}>
                        <TableCell>{currentTime.toString()}</TableCell>
                        {
                            _.map(days, day => {

                                const pointTime = day.clone().hour(currentTime.hour).minute(currentTime.minute);
                                const appointment = _.find(appointments, (a: ITimeSlot) =>
                                    moment(a.dateTime).isSame(pointTime));

                                if (appointment) {
                                    _.remove(appointments, a => a === appointment);
                                }

                                const isFree = appointment && appointment.isFree;

                                return (
                                    <TableCell
                                        key={day.weekday()}>{isFree ? (
                                            <Button
                                                size="small"
                                                variant="contained"
                                                color="success"
                                                onClick={() => this.props.onSelectAppointment?.(appointment)}>
                                                {moment(appointment.dateTime).format("MMM DD. HH:mm")}
                                            </Button>
                                        ) : appointment && (
                                            <p>Foglalt</p>
                                        )}
                                    </TableCell>
                                );
                            })
                        }
                    </StyledTableRow>
                );

            }

            currentTime = currentTime.withAddedMinutes(this.props.slotDuration);
            // style = style == styles.tableRowAlter1 ? styles.tableRowAlter2 : styles.tableRowAlter1;

        }

        return returnArray;
    }

    correctWeekend(newWeek: moment.Moment, forward: boolean): moment.Moment {

        if (newWeek.isoWeekday() === 6) {
            return forward ? newWeek.add(2, "days") : newWeek.subtract(1, "days");
        }

        if (newWeek.isoWeekday() === 7) {
            return forward ? newWeek.add(1, "days") : newWeek.subtract(2, "days");
        }

        return newWeek;
    }

    setWeekToCurrent = () => {

        let newValue = this.correctWeekend(this.days === 5 ? moment().startOf("week") : moment().startOf("day"), true);

        if (newValue.isAfter(this.props.maxDate)) {
            newValue = moment(this.props.maxDate);
        }

        if (newValue.isBefore(this.props.minDate)) {
            newValue = moment(this.props.minDate);
        }

        if (this.props.onWeekDateChanged) {
            this.props.onWeekDateChanged(newValue.toDate());
        }
    };

    setWeekToNext = () => {
        const newValue = this.props.days === 5 ? moment(this.props.weekDate).add(1, 'week') :
            this.correctWeekend(moment(this.props.weekDate).add(this.days, 'day'), true);

        if (newValue.isAfter(this.props.maxDate)) {
            return;
        }

        if (newValue.isBefore(this.props.minDate)) {
            return;
        }

        if (this.props.onWeekDateChanged) {
            this.props.onWeekDateChanged(newValue.toDate());
        }
    };

    setWeekToPrev = () => {

        const newValue = this.props.days === 5 ? moment(this.props.weekDate).subtract(1, 'week') :
            this.correctWeekend(moment(this.props.weekDate).subtract(this.days, 'day'), false);

        if (newValue.isAfter(this.props.maxDate)) {
            return;
        }

        if (newValue.isBefore(this.props.minDate)) {
            return;
        }

        if (this.props.onWeekDateChanged) {
            this.props.onWeekDateChanged(newValue.toDate());
        }

    }

    private getOptions() {
        const res: any[] = [];
        const startFrom = moment(this.props.minDate);
        let dt = startFrom;
        let key = 0;

        while (dt.isSameOrBefore(this.props.maxDate)) {

            res.push({
                key: key++,
                value: dt.toDate().toISOString(),
                text: dt.format("YYYY.MM.DD. dddd")
            });

            dt = this.correctWeekend(dt.add(this.days, "day"), true);

        }

        return res;
    }

    private dayChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (this.props.onWeekDateChanged) {
            this.props.onWeekDateChanged(moment(e.target.value).toDate());
        }
    }

    private renderDayDropdown() {
        return (
            <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Dátum:
                </InputLabel>
                <NativeSelect
                    inputProps={{
                        name: 'date',
                        id: 'uncontrolled-native',
                    }}
                    onChange={this.dayChangeHandler}
                    value={this.props.weekDate.toISOString()}
                >
                    {this.getOptions().map(opt => (
                        <option value={opt.value} key={opt.key}>{opt.text}</option>
                    ))}
                </NativeSelect>
            </FormControl>
        );
    }

    render() {

        const firstDate = moment(this.props.weekDate);
        const dates = [
            firstDate.clone()
        ];

        for (let d = 1; d < this.days; d++) {
            dates.push(firstDate.clone().add(d, 'day'));
        }


        return (
            <Table >
                <TableHead>
                    <TableRow>
                        {this.props.isMobile ? (
                            <TableCell colSpan={2}>
                                {this.renderDayDropdown()}
                            </TableCell>
                        ) : (
                            <>
                                <TableCell width="20%">
                                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                                        <Button onClick={this.setWeekToCurrent}>Ma</Button>
                                        <Button onClick={this.setWeekToPrev}><ChevronLeftIcon /></Button>
                                        <Button onClick={this.setWeekToNext}><ChevronRightIcon /></Button>
                                    </ButtonGroup>
                                </TableCell>
                                {
                                    _.map(dates, d => (
                                        <TableCell width={`${Math.round(80 / dates.length)}%`} key={d.weekday()}>{d.format("MM.DD.")}<br />{d.format("dddd")}</TableCell>
                                    ))
                                }
                            </>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.renderTimeslots(dates)}
                </TableBody>
            </Table>
        );
    }

}