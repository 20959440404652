import moment from "moment";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Components/App";
import "moment/locale/hu";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import NavigationStore from "Stores/NavigationStore";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers";

const history = createBrowserHistory();
NavigationStore.init(history);
moment.locale("hu");
const theme = createTheme();

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <HistoryRouter history={history}>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"hu"}>
                    <App />
                </LocalizationProvider>
            </HistoryRouter>
        </ThemeProvider>
    </React.StrictMode>
);