import { useEffect } from "react";
import { useParams } from "react-router";
import ApplicationStore from "Stores/ApplicationStore";

function SubjectPage() {
    const { subjectId } = useParams();
    useEffect(() => ApplicationStore.loadSubjectPage(subjectId!), [subjectId]);

    return null;
}

export default SubjectPage;