import { Container, Paper, TextField, Typography } from "@mui/material";
import React, { useCallback, useState } from "react";
import Steps from "Components/Controls/Steps";
import NavButtons from "Components/Controls/NavButtons";
import ApplicationStore from "Stores/ApplicationStore";
import PageWrapper from "Components/Controls/PageWrapper";

export default function IdentifyPage() {

    const [subjectId, _setSubjectId] = useState("");

    const next = useCallback(() => {
        ApplicationStore.setSubjectId(subjectId);
    }, [subjectId]);

    const setSubjectId = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const transformToUppercase = ApplicationStore.parameters!.get("transform_subject_id_to_uppercase") === "true";
        const transformToLowercase = ApplicationStore.parameters!.get("transform_subject_id_to_lowercase") === "true";

        let newValue = e.target.value;

        if (transformToUppercase) newValue = newValue.toUpperCase();
        if (transformToLowercase) newValue = newValue.toLowerCase();

        _setSubjectId(newValue);
    }, [_setSubjectId]);

    return (
        <PageWrapper>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography component="h1" variant="h4" align="center">
                    {ApplicationStore.parameters!.get("text__identify_page_title")}
                </Typography>
                <p style={{ textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: ApplicationStore.parameters!.get("text__identify_page_message")! }} />
                <Steps activeStep={0} />
                <TextField
                    required
                    label={ApplicationStore.parameters!.get("text__subject_id")}
                    fullWidth
                    variant="outlined"
                    value={subjectId}
                    onChange={setSubjectId}
                />
                <NavButtons onNext={next} />
            </Paper>
        </PageWrapper>
    );
}