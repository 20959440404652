import { Paper, Typography, Box, CircularProgress, Table, TableBody, TableRow, TableCell, Button, Container } from "@mui/material";
import PageWrapper from "Components/Controls/PageWrapper";
import { observer } from "mobx-react";
import moment from "moment";
import { useEffect } from "react";
import { useParams } from "react-router";
import ApplicationStore from "Stores/ApplicationStore";

function ExistingPage() {
    const { subjectId } = useParams();
    useEffect(() => ApplicationStore.loadExistingPage(subjectId!), [subjectId]);

    return (
        <PageWrapper>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography component="h1" variant="h4" align="center">
                    {ApplicationStore.parameters!.get("text__existing_page_title")}
                </Typography>
                <p dangerouslySetInnerHTML={{ __html: ApplicationStore.parameters!.get("text__existing_page_message")! }} />
                {ApplicationStore.isLoading || !ApplicationStore.existingAppointment ? (
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress disableShrink />
                    </Box>
                ) : (
                    <>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell width={5}>{ApplicationStore.parameters!.get("text__subject_id")}:</TableCell>
                                    <TableCell>{ApplicationStore.subjectId}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell width={5}>Időpont:</TableCell>
                                    <TableCell>{ApplicationStore.existingAppointment.dateTime.format("YYYY.MM.DD. HH:mm")}</TableCell>
                                </TableRow>
                                {!ApplicationStore.existingAppointment.isApproved && (
                                    <TableRow>
                                        <TableCell width={5}>Megerősíthető:</TableCell>
                                        <TableCell>{Math.floor(ApplicationStore.confirmationSecondsLeft / 60)} perc {ApplicationStore.confirmationSecondsLeft % 60} másodpercig</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: 2 }}>
                            {!ApplicationStore.existingAppointment.isApproved && (
                                <Button size="large" variant="contained" color="success" onClick={ApplicationStore.approveAppointment}>Megerősítés</Button>
                            )}
                            <Button size="large" variant="outlined" color="error" onClick={ApplicationStore.cancelAppointment}>Lemondás</Button>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 2 }}>
                            <Button variant="outlined" onClick={ApplicationStore.startOver}>Új bejelentkezés</Button>
                        </Box>
                    </>
                )}
            </Paper>
        </PageWrapper>
    );
}

export default observer(ExistingPage);