import ApiAdapterBase from "ApiAdapter/ApiAdapterBase";
import { Config } from "Config";

const baseUrl = `${Config.baseUrl}/api/subject`;
const headers = {
    "Content-Type": "application/json"
};

class SubjectApiAdapter extends ApiAdapterBase {

    public async getPlannedAppointmentAsync(subjectId: string): Promise<string | null> {
        const response = await fetch(`${baseUrl}/${encodeURIComponent(subjectId)}/plannedAppointment`, {
            method: "GET",
            headers
        });

        this.handleError(response);
        const result = await response.json();
        return result.value;
    }
}

export default new SubjectApiAdapter();