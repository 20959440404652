import { Step, StepLabel, Stepper } from "@mui/material";
import React from "react";

interface IStepsProps {
    activeStep: number;
}

export default function Steps(props: IStepsProps) {
    return (
        <Stepper activeStep={props.activeStep} sx={{ pt: 3, pb: 5, ml: { xs: -2, sm: 0 }, mr: { xs: -2, sm: 0 } }}>
            <Step>
                <StepLabel>Rendszám</StepLabel>
            </Step>
            <Step>
                <StepLabel>Időpont</StepLabel>
            </Step>
            <Step>
                <StepLabel>Foglalás</StepLabel>
            </Step>
        </Stepper>
    );
}