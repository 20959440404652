import AdminKeyStore from "ApiAdapter/Admin/AdminKeyStore";
import ApiAdapterBase from "ApiAdapter/ApiAdapterBase";
import { Config } from "Config";
import { IPagedItems } from "Model/IPagedItems";
import { ISubjectAppointment } from "Model/ISubjectAppointment";
import moment from "moment";

const baseUrl = `${Config.baseUrl}/api/admin/appointment`;
const headers = {
    "Content-Type": "application/json"
};

class AdminAppointmentApiAdapter extends ApiAdapterBase {
    public async getAsync(from: moment.Moment, to: moment.Moment, search: string | null, orderBy: string, orderByDesc: boolean, skip: number, take: number): Promise<IPagedItems<ISubjectAppointment>> {
        const qp = new URLSearchParams();
        qp.set("skip", skip.toString());
        qp.set("take", take.toString());
        qp.set("from", from.toISOString());
        qp.set("to", to.toISOString());
        if (search) qp.set("search", search);
        qp.set("orderBy", orderBy);
        qp.set("orderByDesc", orderByDesc ? "true" : "false");

        const response = await fetch(`${baseUrl}?${qp.toString()}`, {
            method: "GET",
            headers: {
                ...headers,
                "Authorization": AdminKeyStore.getAdminKey()
            }
        });

        if (response.status === 401) {
            AdminKeyStore.clear();
        }

        this.handleError(response);
        const result = await response.json();

        const items = (result.items as any[]).map<ISubjectAppointment>(i => ({
            appointmentDateTime: moment(i.appointmentDateTime).format("L LT"),
            subject: {
                id: i.subject.id,
                plannedAppointment: i.subject.plannedAppointment,
                name: i.subject.name,
                email: i.subject.email?.toLowerCase(),
                phone: i.subject.phone,
                taxNumber: i.subject.taxNumber,
                apNumber: i.subject.apNumber,
                misc: i.subject.misc,
            }
        }));

        return {
            items,
            totalCount: result.totalCount
        }
    }
}

export default new AdminAppointmentApiAdapter();