import { AppBar, Toolbar, Typography, Container } from "@mui/material";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Copyright from "./Copyright";
import IdentifyPage from "./Pages/IdentifyPage";
import ApplicationStore from "Stores/ApplicationStore";
import SubjectPage from "Components/Pages/SubjectPage";
import TimeSlotsPage from "Components/Pages/TimeSlotsPage";
import ExistingPage from "Components/Pages/ExistingPage";
import { observe } from "mobx";
import { observer } from "mobx-react";
import SubjectList from "Components/Pages/SubjectList";
import SubjectAppointmentList from "Components/Pages/SubjectAppointmentList";

function App() {

    useEffect(() => ApplicationStore.load(), []);

    if (ApplicationStore.parameters === null) {
        return <p>Betöltés...</p>;
    }

    return (
        <>
            <Routes>
                <Route path="/" element={<IdentifyPage />} />
                <Route path="/:subjectId" element={<SubjectPage />} />
                <Route path="/:subjectId/timeslots" element={<TimeSlotsPage />} />
                <Route path="/:subjectId/existing" element={<ExistingPage />} />

                <Route path="/admin/subjectList" element={<SubjectList />} />
                <Route path="/admin/appointmentList" element={<SubjectAppointmentList />} />

            </Routes>
            <Copyright />
        </>
    );
}

export default observer(App);