import { Paper, Typography, Box, CircularProgress, Table, TableBody, TableRow, TableCell, Button, Container, TextField, AppBar, Toolbar } from "@mui/material";
import { GridColDef, GridRowsProp, GridSortModel } from "@mui/x-data-grid";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import ApplicationStore from "Stores/ApplicationStore";
import { DataGrid } from '@mui/x-data-grid';
import NavigationStore from "Stores/NavigationStore";

function SubjectList() {

    useEffect(() => ApplicationStore.loadSubjectListPage(), []);

    const columns = useMemo<GridColDef[]>(() => {
        const colDataRaw = ApplicationStore.parameters!.get("subject_list_columns")!;
        const cols = colDataRaw.split(";");

        return [
            ...cols.map<GridColDef>(c => {
                const [field, headerName] = c.split(":");
                return {
                    field,
                    headerName,
                    flex: 1,
                    filterable: false
                };
            }),
            {
                field: "id",
                renderCell: p => (
                    <>
                        <Button
                            variant="contained"
                            onClick={() => NavigationStore.navigateTo(`/${p.row.id}`)}
                            size="small"
                        >
                            Foglalás
                        </Button>
                    </>
                )
            }
        ];
    }, []);

    const sortModel: GridSortModel = [
        {
            field: ApplicationStore.subjectListOrderBy,
            sort: ApplicationStore.subjectListOrderByDescending ? "desc" : "asc"
        }
    ];


    return (
        <>
            <AppBar
                position="absolute"
                color="default"
                elevation={0}
                sx={{
                    position: 'relative',
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}
            >
                <Toolbar>
                    <Typography variant="h6" color="inherit" noWrap>
                        {ApplicationStore.parameters!.get("text__subject_list_title")}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
                {/* <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}> */}
                <TextField
                    required
                    label="Keresés"
                    fullWidth
                    variant="outlined"
                    value={ApplicationStore.searchText}
                    onChange={e => ApplicationStore.setSearchText(e.target.value)}
                    sx={{ mt: 1, mb: 1 }}
                />
                {ApplicationStore.isLoading || !ApplicationStore.subjectList ? (
                    <Box sx={{ display: "flex" }}>
                        <CircularProgress disableShrink />
                    </Box>
                ) : (
                    <div style={{ height: "calc(100vh - 190px)", width: '100%' }}>
                        <DataGrid
                            rows={ApplicationStore.subjectList.items}
                            columns={columns}
                            sortModel={sortModel}
                            sortingMode="server"
                            paginationMode="server"
                            pagination={true}
                            page={ApplicationStore.subjectListPage}
                            pageSize={ApplicationStore.subjectListPageSize}
                            onPageChange={ApplicationStore.setSubjectListPage}
                            rowCount={ApplicationStore.subjectList.totalCount}
                            onSortModelChange={s => ApplicationStore.setSubjectListOrderBy(s.length === 0 ? "Name" : s[0].field, s.length === 0 ? false : s[0].sort === "desc")}
                        />
                    </div>
                )}
                {/* </Paper> */}
            </Container>
        </>
    );
}

export default observer(SubjectList);