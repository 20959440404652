class AdminKeyStore {
    public getAdminKey(): string {
        const cached = localStorage.getItem("admin_key");
        if (cached) return cached;

        const key = prompt("Admin kulcs:");
        if (key) {
            localStorage.setItem("admin_key", key);
            return key;
        }

        throw new Error();
    }

    public clear() {
        localStorage.removeItem("admin_key");
    }
}

export default new AdminKeyStore();