import ApiAdapterBase from "ApiAdapter/ApiAdapterBase";
import { Config } from "Config";
import { ITimeSlot } from "Model/ITimeSlot";
import { IAppointmentSchedule } from "Model/IAppointmentSchedule";
import { IExistingAppointment } from "Model/IExistingAppointment";
import { ISubjectAndAppointment } from "Model/ISubjectAndAppointment";
import moment from "moment";

const baseUrl = `${Config.baseUrl}/api/appointment`;
const headers = {
    "Content-Type": "application/json"
};

class AppointmentApiAdapter extends ApiAdapterBase {

    public async getExistingAppointmentAsync(subjectId: string): Promise<IExistingAppointment | null> {
        const response = await fetch(`${baseUrl}/existing/${encodeURIComponent(subjectId)}`, {
            method: "GET",
            headers
        });

        this.handleError(response);
        const result = await response.json();

        return result.value ? {
            id: result.value.id,
            autoCancelAfterSeconds: result.value.autoCancelAfterSeconds,
            dateTime: moment(result.value.dateTime),
            isApproved: result.value.isApproved
        } : null;
    }

    public async getAsync(from: moment.Moment, to: moment.Moment): Promise<IAppointmentSchedule> {
        const response = await fetch(`${baseUrl}?from=${encodeURIComponent(from.toISOString())}&to=${encodeURIComponent(to.toISOString())}`, {
            method: "GET",
            headers
        });

        this.handleError(response);
        const result = await response.json();

        return {
            schedule: {
                validFrom: moment(result.schedule.validFrom),
                dayStartTime: result.schedule.dayStartTime,
                dayEndTime: result.schedule.dayEndTime,
                numberOfAppointmentsInSlot: result.schedule.numberOfAppointmentsInSlot,
                slotDurationMinutes: result.schedule.slotDurationMinutes,
            },
            timeSlots: (result.timeSlots as any[]).map<ITimeSlot>(a => ({
                appointmentId: a.appointmentId,
                dateTime: moment(a.dateTime),
                isFree: a.isFree
            }))
        };
    }

    public async selectAsync(body: ISubjectAndAppointment): Promise<IExistingAppointment | null> {
        const response = await fetch(`${baseUrl}/select`, {
            method: "POST",
            headers,
            body: JSON.stringify(body)
        });

        this.handleError(response);
        const result = await response.json();
        return this.mapExistingAppointment(result);
    }

    public async approveAsync(body: ISubjectAndAppointment): Promise<IExistingAppointment | null> {
        const response = await fetch(`${baseUrl}/approve`, {
            method: "POST",
            headers,
            body: JSON.stringify(body)
        });

        this.handleError(response);
        const result = await response.json();
        return this.mapExistingAppointment(result);
    }

    public async cancelAsync(body: ISubjectAndAppointment): Promise<void> {
        const response = await fetch(`${baseUrl}/cancel`, {
            method: "POST",
            headers,
            body: JSON.stringify(body)
        });

        this.handleError(response);
    }

    private mapExistingAppointment(data: any): IExistingAppointment | null {
        return data.value ? {
            id: data.value.id,
            autoCancelAfterSeconds: data.value.autoCancelAfterSeconds,
            isApproved: data.value.isApproved,
            dateTime: moment(data.value.dateTime)
        } : null;
    }
}

export default new AppointmentApiAdapter();